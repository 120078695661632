import React from "react"
import {
  Snackbar as MuiSnackbar,
  SnackbarContent,
  Alert,
} from "@mui/material"
import { useAdminDispatch, useAdminState } from "../context/AdminContext"
import { SET_SNACKBAR } from "../context/actions/snackbar"

export default function Snackbar() {
  const { snackbar } = useAdminState()
  const dispatch = useAdminDispatch()

  const onClickCloseSnackbar = () => {
    if (snackbar.location) {
      window.location.href = snackbar.location
    } else if (snackbar.reload) {
      window.location.reload()
    }

    dispatch({ type: SET_SNACKBAR, payload: { open: false } })
  }

  return (
    <MuiSnackbar
      open={snackbar.open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={snackbar.reload ? 1000 : null}
      onClose={onClickCloseSnackbar}
      disableWindowBlurListener
    >
      {snackbar.severity === "default" ? (
        <SnackbarContent message={snackbar.message} />
      ) : (
        <Alert
          elevation={6}
          variant="filled"
          onClose={onClickCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      )}
    </MuiSnackbar>
  )
}
