import { UserManager, WebStorageStateStore } from "oidc-client-ts";
const isBrowser = typeof window !== "undefined";
const oidcConfig = {
  authority: process.env.GATSBY_AUTHORITY_URL,
  client_id: process.env.GATSBY_CLIENT_ID,
  redirect_uri: process.env.GATSBY_REDIRECT_URL,
  post_logout_redirect_uri: process.env.GATSBY_LOGOUT_REDIRECT_URL,
  automaticSilentRenew: true, // Enables silent authentication
  silent_redirect_uri: `${process.env.GATSBY_REDIRECT_URL}/silent-renew`,
  response_type: "code",
  scope: "openid profile email groups params",
  userStore: isBrowser
    ? new WebStorageStateStore({ store: localStorage })
    : undefined, // Prevent SSR issues
};

const userManager = new UserManager({
  ...oidcConfig,
  code_challenge_method: "S256",
});

export function login() {
  userManager.signinRedirect();
}

export async function handleCallback() {
  try {
    const user = await userManager.signinRedirectCallback();

    if (user) {
      await userManager.storeUser(user);
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    } else {
      console.log("No user data retrieved, redirecting to login...");
      await userManager.signinRedirect();
    }
  } catch (error) {
    console.error("Callback handling error:", error);
    await userManager.signinRedirect(); // Force fresh login if error occurs
  }
}

export function logout() {
  // Clear user data from localStorage on logout
  localStorage.removeItem("user");
  userManager.signoutRedirect();
}

export function getUser() {
  return userManager.getUser();
}

// Optional helper function to retrieve user data from localStorage
export function getStoredUser() {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
}
