import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  IconButton,
  Button,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { useAdminState } from "../context/AdminContext";
import { logout } from "../services/auth";
import Logo from "../images/CPAxtra-Lotuss_Logo.png";
import { navigate } from "gatsby";
import {
  HomeIcon,
  UserGroupIcon,
  Squares2X2Icon,
  CodeBracketIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/outline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import getJira from "../services/getJira";
import moment from "moment";

const HeaderRoot = styled("div")({
  flexGrow: 1,
  marginBottom: 150,
});

const DrawerContent = styled("div")(({ theme }) => ({
  width: 280,
  background: "#f4f3ef",
  height: "100vh",
  overflowY: "auto",
}));

const LogoImage = styled("img")({
  position: "relative",
  top: 15,
  width: 200,
  cursor: "pointer",
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "rgba(255, 255, 255, 0.15)",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.25)",
  },
  margin: "0 auto",
  [theme.breakpoints.up("sm")]: {
    width: "80%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "inherit",
  zIndex: 1,
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    color: "inherit",
    width: "100%",
    fontSize: "1rem",
  },
  "& .MuiOutlinedInput-root": {
    width: "100%",
    paddingLeft: 50,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: "inherit",
  },
  "& .MuiAutocomplete-clearIndicator": {
    color: "inherit",
  },
}));

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
});

const LeftSection = styled("div")({
  display: "flex",
  alignItems: "center",
});

const RightSection = styled("div")({
  display: "flex",
  alignItems: "center",
});

const Header = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isDashboardOpen, setDashboardOpen] = useState(false);
  const { profile } = useAdminState()?.login?.user || {};
  const { jira, loading } = useAdminState().jira;
  const [searchValue, setSearchValue] = useState(null);
  const startDueDate = moment().startOf("year").format("YYYY-MM-DD");
  const toggleDrawer = (open) => () => setDrawerOpen(open);
  const toggleDashboard = () => setDashboardOpen(!isDashboardOpen);

  const onClickLogout = () => {
    if (typeof window !== "undefined") {
      logout();
      window.location = "/login";
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  const handleSearchSelect = (event, value) => {
    if (value) {
      navigate(`/project-details/pmo/?id=${value.key}`);
    }
  };

  useEffect(() => {
    if (jira.length === 0) {
      getJira(
        `(project = PMO AND duedate >= "${startDueDate}" AND duedate <= endOfYear(+1y)) OR (project = DEM AND status NOT IN ("Done", "On Hold", "Cancel", "Submit To Portfolio"))`,
        "summary, status, duedate, assignee, customfield_10193, customfield_11833, customfield_11758, customfield_11845, customfield_11842, customfield_11844, customfield_11843, customfield_10671, customfield_10651"
      );
    }
  }, [jira]);

  return (
    <HeaderRoot>
      <AppBar position="fixed" className="appbar">
        <StyledToolbar>
          <LeftSection>
            <IconButton onClick={toggleDrawer(true)} color="inherit">
              <MenuIcon />
            </IconButton>
            <LogoImage src={Logo} alt="Logo" onClick={() => navigate("/")} />
          </LeftSection>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledAutocomplete
              options={jira || []}
              getOptionLabel={(option) => option.fields?.summary || ""}
              onChange={handleSearchSelect}
              value={searchValue}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search projects..."
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Box>
                    <strong>{option.key}</strong> - {option.fields?.summary}
                  </Box>
                </Box>
              )}
            />
          </Search>

          <RightSection>
            <Button
              className="logout-button"
              color="inherit"
              onClick={onClickLogout}
            >
              Logout
            </Button>
          </RightSection>
        </StyledToolbar>
      </AppBar>

      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <DrawerContent>
          <List>
            <ListItem>
              <ListItemText primary={`Hi! ${profile?.given_name || ""}`} />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleNavigation("/")}>
              <HomeIcon className="navigation-icon" />
              <ListItemText primary="Home" />
            </ListItem>

            <ListItem button onClick={toggleDashboard}>
              <Squares2X2Icon className="navigation-icon" />
              <ListItemText primary="Dashboard" />
              {isDashboardOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={isDashboardOpen} timeout="auto" unmountOnExit>
              <div className="paddLeft">
                <List component="div" disablePadding>
                  <ListItem button onClick={() => handleNavigation("/")}>
                    <AcademicCapIcon className="navigation-icon" />
                    <ListItemText primary="PMO" />
                  </ListItem>
                </List>

                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => handleNavigation("/dashboard/localDev")}
                  >
                    <CodeBracketIcon className="navigation-icon" />
                    <ListItemText primary="Local Dev" />
                  </ListItem>
                </List>
              </div>
            </Collapse>

            <ListItem button onClick={() => handleNavigation("/resources")}>
              <UserGroupIcon className="navigation-icon" />
              <ListItemText primary="Resources" />
            </ListItem>
          </List>
        </DrawerContent>
      </Drawer>
    </HeaderRoot>
  );
};

export default Header;
