import React, { useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Header from "./header";
import "./layout.css";
import { Wrapper } from "./styles";
import Snackbar from "../shared/Snackbar";
import { navigate } from "gatsby";
import { adminContextStore } from "../context/store/createStore";
import { SET_LOGIN } from "../context/actions/auth";
import { getStoredUser } from "../services/auth";
import { getRoleType, isPermissionAllowed } from "../utils/roleChecker";

const Layout = ({ children, location }) => {
  const { dispatch } = adminContextStore;
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  const fetchUser = useCallback(async () => {
    const isLoginPage = location?.pathname === "/login";

    try {
      const currentUser = await getStoredUser();

      if (!currentUser) {
        !isLoginPage && navigate("/login");
        return;
      }

      const userGroups = currentUser?.profile?.groups;
      dispatch({
        type: SET_LOGIN,
        payload: {
          submitting: false,
          isLogin: true,
          user: currentUser,
          role: getRoleType(userGroups),
          isLevel3and4: isPermissionAllowed(userGroups),
        },
      });

      isLoginPage && navigate("/");
    } catch (error) {
      console.error("Error fetching user:", error);
      !isLoginPage && navigate("/login");
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (process.env.GATSBY_APP_ENV !== "development") {
      fetchUser();
    }
  }, [fetchUser]);

  return (
    <Wrapper>
      <Snackbar />
      <Header />
      <main>{children}</main>
      <footer> {currentYear}, Lotuss PMO</footer>
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Layout;
